import "@fontsource/nunito";
import "@fontsource/roboto";
import React from 'react';
import { ThemeProvider } from "react-jss";
import { useSelector } from "react-redux";

import Main from './components/Main';
import { themeSelector} from "./store/selectors";

import useGlobalStyles from "./styles";
import { theme } from "./theme";

const Wrapper = () => {
    useGlobalStyles();
    return <div>
        <Main />
    </div>;
};

const App = () => {
    const selectedTheme = useSelector(themeSelector);
    return (
        <ThemeProvider theme={theme[selectedTheme]}>
            <Wrapper />
        </ThemeProvider>
    );
} 

export default App;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";

import App from './main/App';
import { store } from "./main/app/store";
import reportWebVitals from './reportWebVitals';

const element = document.getElementById('root');
if (element) {
  const root = ReactDOM.createRoot(element);
  
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

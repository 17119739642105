import { createUseStyles } from "react-jss";
import { AppTheme } from "./theme/types";

const globalStyle = createUseStyles(({unit}: AppTheme) => ({
    "@global": {
        body: {
            fontFamily: "Nunito",
            fontWeight: 400,
            fontSize: unit * 2,
            lineHeight: `${unit * 3}px`,
            margin: 0,
            color: 'white',
            background: 'black'
        },
        code: {
            fontFamily: ["source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New", "monospace"]
        },
        button: {
            fontFamily: "Nunito",
            fontWeight: 500,
            fontSize: unit * 1.75,
            lineHeight: `${unit * 2}px`
        },
        p: {
            fontFamily: "Nunito",
            fontWeight: "400",
            fontSize: unit * 1.75,
            lineHeight: `${unit * 3}px`
        },
        h1: {
            fontFamily: "Nunito",
            fontWeight: 300,
            fontSize: unit * 12,
            lineHeight: `${unit * 7}px`
        },
        h2: {
            fontFamily: "Nunito",
            fontWeight: 300,
            fontSize: unit * 7.5,
            lineHeight: `${unit * 5}px`
        },
        h3: {
            fontFamily: "Nunito",
            fontWeight: 400,
            fontSize: unit * 6,
            lineHeight: `${unit * 4}px`
        },
        h4: {
            fontFamily: "Nunito",
            fontWeight: 400,
            fontSize: unit * 4.25,
            lineHeight: `${unit * 3}px`
        },
        h5: {
            fontFamily: "Nunito",
            fontWeight: 400,
            fontSize: unit * 3,
            lineHeight: `${unit * 2.5}px`
        },
        h6: {
            fontFamily: "Nunito",
            fontWeight: 500,
            fontSize: unit * 2.5,
            lineHeight: `${unit * 1.5}px`
        },
        sub: {
            fontFamily: "Nunito",
            fontWeight: 400,
            fontSize: unit * 2,
            lineHeight: `${unit * 3}px`
        },
        header: {
            height: "4vh",
            width: "100vw",
            position: "absolute",
            top: "0"
        },
        main: {
            height: "86vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column"
        },
        footer: {
            height: "5vh",
            width: "100vw",
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "stretch",
            position: "fixed",
            bottom: 0
        }
    }
}));

export default globalStyle;

export enum State {
    List,
    Entry,
    AddEntry
}

export enum EntryState {
    Edit,
    View
}

export enum Theme {
    Light = "light",
    Dark = "dark"
}

export enum Color {
    Red = "red",
    Green = "green",
    Yellow = "yellow"
}

import React, { useEffect, useState } from 'react';
import { MailOutlined } from "@mui/icons-material";
import { ReactComponent as Logo } from '../assets/LogoFish.svg';
import { ReactComponent as VKLogo } from '../assets/vk_logo.svg';
import useStyles from './styles';

const Main = () => {
  const { container, content, header, main, logo, footer, menu, menuItem, mobileFooter, selectedOption, footerItem, mailLogo, mobileLogo, mobileContent} = useStyles();

  const menuItems = ['Миссия', 'Услуги', 'О нас'];

  const showContent = (contentType: string) => {
    switch (contentType) {
      case 'Миссия': 
        return <>
              <ul>
                <li>Достижение максимально эффективной работы наших клиентов путем индивидуального подхода к каждой задаче.</li>
                <li>Профессиональной комплексной бизнес-аналитическе производственных, трудовых и технологических процессов, разработки и внедрению наиболее оптимальных решений.</li>
              </ul>
            </>;
      case 'О нас': 
        return <>
                <div>
                  <h5>Богуславский Максим</h5>
                  <h6>Основатель | генеральный директор</h6>
                </div>
                <ul>
                  <li>Известный Front-end разработчик, проектный-менеджер и бизнес-аналитик с профессиональным образованием в сфере IT, а так же смежных областях.</li>
                  <li>Учится в МГТУ им. Баумана (ИУ-7), получил степень MBA в Финансовом Университете при Правительстве РФ, а так же закончил специалитет в МУИВ им. Витте.</li>
                </ul>
                </>;
      case 'Услуги': 
        return <>
                <ul>
                  <li>Построение процесса найма и выявление ключевых компетенций. Найм, обучение и мотивация персонала.</li>
                  <li>Управление проектами. Выявление потребностей пользоватей, анализ и проработка требований, составление дорожной карты проекта.</li>
                  <li>Разработка: приложений SPA, парсеров и Телеграм ботов для WEB, разработка приложений для Linux и Mac os.</li>
                  <li>Аудит технических решений.</li>
                </ul>
              </>;
      default: 
      return <></>;
    }
  }
  const [selectedMenuItem, setMenuItem] = useState(menuItems[0]);
  const [width, setWidth] = useState<number>(window.innerWidth);

const isMobile = width <= 768;
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
  
  return (
    !isMobile ? (
    <div className={container}>
      <div className={header}>
        <h2>Alpha Function</h2>
        <h4>Повышаем вашу эффективность!</h4>
        <Logo className={logo} />
      </div>
      
      <div className={main}>
        <div className={menu}>
          {menuItems.map((item, index)=> <h6 className={selectedMenuItem === item ? selectedOption : menuItem} key={index} onClick={() => {setMenuItem(item)}}>{item}</h6>)}
        </div>
        <div className={content}>
          {
            showContent(selectedMenuItem)
          }  
        </div>
      </div>
      <div className={footer}>
              <a className={footerItem} href='https://vk.com/alphafunction'><VKLogo /></a>
              <a className={footerItem} href='mailto:boss@alpha-function.ru'><MailOutlined className={mailLogo} /></a>
      </div>
    </div>
    ): (
      <div className={container}>
        <div className={mobileContent}>
          <h3>Alpha Function</h3>
          <h6>Повышаем вашу эффективность!</h6>
          <Logo className={mobileLogo} />
          {menuItems.map((item, index)=> 
          (<div key={index}>
            <h6>{item}</h6>
            <div>
              {showContent(item)}
            </div>
          </div>))}
          <div className={mobileFooter}>
            <a href='mailto:boss@alpha-function.ru'><MailOutlined className={mailLogo} /></a>
          </div>
        </div>
      </div>
    )
  );
}

export default Main;

import { createUseStyles } from "react-jss";
import { AppTheme } from "../theme/types";

const styles = createUseStyles(({ unit, colors: { black, white, secondaryText, charcoal } }: AppTheme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent:  'center',
    },
    header: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow: 4,
        minWidth: '900px'
    },
    logo:  {
        height: unit * 68,
        margin: -21.5 * unit,
        display: 'inline-flex',
        zIndex: -1
    },
    menu: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        flexGrow: 2
    },
    menuItem: {
        padding: unit * 2,
        opacity: 0.7,
        '&:hover': {
            cursor: 'pointer',
            opacity: 1,
            borderBottom: [1, 'solid', charcoal],
            borderRadius: 4
        },
    },
    selectedOption: {
        padding: unit * 2,
        opacity: 0.7,
        '&:hover': {
            cursor: 'pointer',
            opacity: 1,
            borderBottom: [1, 'solid', charcoal],
            borderRadius: 4
        },
        borderBottom: ['thin', 'double', '#f77713'],
    },
    footer: {
        position: 'relative',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: ['thin', 'double', '#f77713'],
        width: '100%',
        padding: unit * 1.5
    },
    footerItem: {
        margin: [0, unit * 12.5, 0, unit * 12.5]
    },
    mailLogo: {
        color: '#f77713',
        width: unit * 8,
        height: unit * 8
    },
    content: {
        overflow: 'scroll',
        overflowX: 'hidden',
        display: 'inline-flex',
        height: unit * 25,
        minWidth: unit * 60,
        width: unit * 100
    },
    mobileLogo: {
        height: unit * 36,
        margin: [unit * -12.5, 0, unit * -12.5, 0],
        display: 'inline-flex',
        zIndex: -1
    },
    mobileContent: {
        margin: unit * 2
    },
    mobileFooter: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

export default styles;

import { AppThemes } from "./types";

export const theme: AppThemes = {
    light: {
        unit: 8,
        colors: {
            grayLight: "#F6F6F6",
            blackLight: "#464646",
            black: "#000000",
            white: "#FFFFFF",
            lightGreen: "#E7F8CB",
            green: "#A3D84D",
            lightYellow: "#FFF0BA",
            yellow: "#E9C056",
            red: "#F66C63",
            lightRed: "#FBDCDA",
            gray: "#9FA5B0",
            charcoal: "#EFEFEF",
            surfaceOverlay: "#21212114",
            subtitleTwo: "#B8BCC2",
            secondaryText: "#717885",
            title: "#242629"
        }
    },
    dark: {
        unit: 8,
        colors: {}
    }
};
  